import * as React from "react"
import { graphql } from "gatsby"

import SEO from "../components/seo"
import Layout from "../components/layout"
import { expectAuthenticationState } from "../components/authentication"

import "./markdownTemplate.module.css"

function Template({ data }) {
  const { markdownRemark } = data
  const { html, frontmatter } = markdownRemark
  return (
    <Layout>
      <SEO title={frontmatter.title} />
      <div className="markdown">
        <div
          className="markdownContent"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    </Layout>
  )
}

const AuthenticatedTemplate = expectAuthenticationState({
  connected: true,
  onboarded: true,
  orangeInternal: false,
})(Template)

const OrangeInternalTemplate = expectAuthenticationState({
  connected: true,
  onboarded: true,
  orangeInternal: true,
})(Template)

export default function TemplateWithAuthenticationCheck(props) {
  const {
    data: {
      markdownRemark: {
        frontmatter: { requireAuthentication = "false"},
      },
    },
  } = props
  if (requireAuthentication === "internal" ) {
    return <OrangeInternalTemplate {...props} />
  }
  else if (requireAuthentication === "true") {
    return <AuthenticatedTemplate {...props} />
  } else {
    return <Template {...props} />
  }
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        requireAuthentication
      }
    }
  }
`
